import axios, { AxiosInstance } from 'axios';
import { AntiForgery } from './antiForgery';
import { sendEvent } from "../hooks/GoogleAnalyticsHook";

const instance: AxiosInstance = axios.create();

instance.defaults.baseURL = process.env.REACT_APP_API_BASEURL;
instance.defaults.withCredentials = true;

const antiForgery = new AntiForgery(instance);

instance.interceptors.request.use(
    request => antiForgery.addToken(request),
);

//use interceptors if you want to modify every request and response you make with axios
instance.interceptors.response.use(
    // Any status code that lie within the range of 2xx cause this function to trigger
    response => response,
    //Any status code that lie outside the range of 2xx cause this function to trigger
    error => onResponseRejected(error)
);

export const onResponseRejected = (error: any) => {

    sendEvent("error", "Error occured with code " + error.response?.status);

    if (error.response?.status === 401 && error.response?.data.LoginUrl) {
        window.location.href = error.response.data.LoginUrl;
        return Promise.reject(error);
    }

    if (error.response?.status === 429) {
        window.location.href = "/error/429";
        return Promise.reject(error);
    }

    if (error.response?.status === 403) {
        window.location.href = "/error/403";
        return Promise.reject(error);
    }

    if (error.response?.status === 413) {
        window.location.href = "/error/413";
        return Promise.reject(error);
    }

    if (error.response?.status !== 500) {
        const message = error.response ?? error;
        return Promise.reject(message);
    }

    window.location.href = "/error/500"
    return Promise.reject(error);
}

export default {
    instance
}
